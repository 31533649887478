<template>
  <div>
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card-title class="elevation-0">
          <span class="itemHeading">EMPLOYEEWISE REPORTS</span>
          <v-flex xs12 align-self-center pt-2>
            <v-layout wrap justify-start>
              <v-flex
                xs12
                sm3
                md3
                lg3
                xl3
                pa-1
                text-left
                style="font-family: poppinsregular"
              >
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Pick a date range</span
                >
                <v-select
                  v-model="day"
                  :items="days"
                  item-text="days"
                  item-value="days"
                  outlined
                  class="rounded-xl"
                  clearable
                  dense
                  @change="dateFilter(day)"
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                sm3
                md2
                text-left
                pa-1
                v-if="day == 'Custom'"
                style="font-family: poppinsregular"
              >
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Pick from date</span
                >
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormattedFrom"
                      outlined
                      class="rounded-xl"
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="(menu1 = false)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm3
                md2
                text-left
                pa-1
                v-if="day == 'Custom'"
                style="font-family: poppinsregular"
              >
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Pick to date</span
                >
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormattedTo"
                      outlined
                      class="rounded-xl"
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="(menu2 = false)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm3
                md3
                lg3
                xl2
                pa-1
                text-left
                style="font-family: poppinsregular"
              >
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Pick a employee</span
                >
                <v-autocomplete
                  v-model="member"
                  :items="teamMembers"
                  dense
                  outlined
                  class="rounded-xl"
                  clearable
                  :search-input.sync="search"
                  item-text="name"
                  item-value="_id"
                ></v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm3
                md3
                lg2
                xl2
                pa-1
                pt-9
                text-left
                style="font-family: poppinsregular"
              >
                <v-btn
                  style="font-family: kumbhBold; text-transform: capitalize; font-size: 14px;"
                  dark
                  block
                  class="rounded-xl"
                  color="#005f32"
                  @click="eachEmployee()"
                >
                Excel Download
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card-title class="elevation-0">
          <span class="itemHeading" style="text-transform: uppercase"
            >Monthly Reports</span
          >
          <v-flex xs12 align-self-center pt-2>
            <v-layout wrap justify-start>
              <v-flex
                xs12
                sm3
                md2
                lg2
                xl2
                pa-1
                text-left
                style="font-family: poppinsregular"
              >
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Pick a month</span
                >
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      outlined
                      hide-details
                      dense
                      class="rounded-xl"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    type="month"
                    @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm3
                md3
                lg2
                xl2
                pa-1
                pt-9
                text-left
                style="font-family: poppinsregular"
              >
                <v-btn
                  style="font-family: kumbhBold; text-transform: capitalize; font-size: 14px;"
                  dark
                  block
                  class="rounded-xl"
                  color="#005f32"
                  @click="monthlyReport()"
                >
                Excel Download
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      visible: false,
      multi: true,
      showsnackbar: false,
      orgId: localStorage.getItem("orgid"),
      userId: localStorage.getItem("userid"),
      msg: "",
      menu1: false,
      menu2: false,
      menu3: false,
      fromDate: new Date(),
      toDate: new Date(),
      day: "",
      days: [
        "Last 7 days",
        "Last 30 days",
        "Last 3 months",
        "Last 6 months",
        "Last year",
        "Custom",
      ],
      menu: false,
      member: "",
      teamMembers: [],
      date: new Date().toISOString().substr(0, 7),
      computedDateFormatted: "",
      search: "",
    };
  },
  computed: {
    computedDateFormattedFrom() {
      return this.formatDatePicker(this.fromDate);
    },
    computedDateFormattedTo() {
      return this.formatDatePicker(this.toDate);
    },
  },
  watch: {
    date() {
      this.currentPage = 1;
      this.computedDateFormatted = this.formatDateMonth(this.date);
    },
    search(val) {
      this.loading = true;
      setTimeout(() => {
        this.getTeamList(val);
        this.loading = false;
      }, 500);
    },
  },
  beforeMount() {
    this.computedDateFormatted = this.formatDateMonth(this.date);

    if (this.computedDateFormattedFrom) {
      this.computedDateFormattedFrom.setDate(this.computedDateFormattedFrom.getDate() - 7);
      this.computedDateFormattedFrom = this.computedDateFormattedFrom.toISOString().substr(0, 10);
    }
    if (this.computedDateFormattedTo) {
      this.computedDateFormattedTo.setDate(this.computedDateFormattedTo.getDate());
      this.computedDateFormattedTo = this.computedDateFormattedTo.toISOString().substr(0, 10);
    }
  },
  mounted() {
    this.getTeamList();
  },
  methods: {
    getTeamList(val) {
      this.visible = true;
      // var data1 = {};
      // data1["orgId"] = this.orgId;
      axios({
        method: "GET",
        url: "/team/members/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          empId: this.userId,
          searchKeyword: val,
        },
      })
        .then((response) => {
          // console.log(response.data.data)
          if (response.data.status === true) {
            this.visible = false;
            this.teamMembers = response.data.data;
          } else if (response.data.status === false) {
            this.visible = false;
          }
        })
        .catch((e) => {
          this.visible = false;
          this.errors.push(e);
          this.msg = "failed";
        });
    },
    eachEmployee() {
      axios({
        method: "POST",
        url: "/attendance/each/list/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          from: this.fromDate,
          to: this.toDate,
          id: this.member,
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Employee_Attendance_Report.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    monthlyReport() {
      axios({
        method: "POST",
        url: "/attendance/employees/datewise/list/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          month: this.computedDateFormatted,
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Monthly_Report.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    dateFilter(day) {
      var to = new Date();
      var from = new Date(to.getTime());
      if (day == "Last 7 days") {
        from = from.setDate(from.getDate() - 7);
        from = new Date(from);
      } else if (day == "Last 30 days") {
        from = from.setDate(from.getDate() - 30);
        from = new Date(from);
      } else if (day == "Last 3 months") {
        from = from.setMonth(from.getMonth() - 3);
        from = new Date(from);
      } else if (day == "Last 6 months") {
        from = from.setMonth(from.getMonth() - 6);
        from = new Date(from);
      } else if (day == "Last year") {
        from = from.setMonth(from.getMonth() - 12);
        from = new Date(from);
      }
      // else if (day == "Lifetime") {
      //   from = "";
      //   to = "";
      // }
      this.fromDate = from.toISOString().substr(0, 10);
      this.toDate = to.toISOString().substr(0, 10);
      this.getData();
    },
    checkDate() {
      // let ndate = new Date().toISOString().substr(0, 10);
      // if (this.fromDate == ndate) {

      var m = new Date(this.fromDate);
      // var j = new Date(this.fromDate);
      var n = new Date(this.toDate);
      if (m <= n && n >= m) {
        const tomorrow = new Date(this.toDate);
        tomorrow.setDate(tomorrow.getDate() - 15);
        // this.fromDate = tomorrow.toISOString().substr(0, 10);
        // }
        // n.setDate(j.getDate() + 13);
        if (n - m < 1296000000) {
          // console.log("GH");
          // console.log("M=", m);
          // console.log("N=", n);
          // console.log("res", n - m);
          n.setDate(n.getDate());
          // console.log("Ms=", m.toISOString().substr(0, 10));
          // console.log("Ns=", n.toISOString().substr(0, 10));
          this.toDate = n.toISOString().substr(0, 10);
          // console.log("GH", this.fromDate);
          this.getData();
        } else {
          // console.log("els casssse");
          // console.log(m);
          // console.log(n);
          m.setMonth(n.getMonth());
          m.setFullYear(n.getFullYear());
          m.setDate(n.getDate() - 15);
          // n.setMonth(m.getMonth());
          // n.setFullYear(m.getFullYear());
          // n.setDate(m.getDate() + 15);
          // console.log("fin", n);
          // console.log("Msss=", m.toISOString().substr(0, 10));
          // console.log("Nsss=", n.toISOString().substr(0, 10));
          // console.log("els case", m.getMonth());
          this.fromDate = m.toISOString().substr(0, 10);
          this.toDate = n.toISOString().substr(0, 10);
          this.getData();
        }
      } else {
        n.setMonth(m.getMonth());
        n.setFullYear(m.getFullYear());
        n.setDate(m.getDate() + 15);
        this.toDate = n.toISOString().substr(0, 10);
        this.getData();
      }
    },
    checkDate1() {
      var m = new Date(this.fromDate);
      var n = new Date(this.toDate);
      if (m <= n && n >= m) {
        if (n - m < 1296000000) {
          // console.log("GH");
          // console.log("M=", m);
          // console.log("N=", n);
          // console.log("res", n - m);
          n.setDate(n.getDate());
          // console.log("Ms=", m.toISOString().substr(0, 10));
          // console.log("Ns=", n.toISOString().substr(0, 10));
          this.toDate = n.toISOString().substr(0, 10);
          // console.log("GH", this.fromDate);
          this.getData();
        } else {
          // console.log("els casssse");
          // console.log(m);
          // console.log(n);
          n.setMonth(m.getMonth());
          n.setFullYear(m.getFullYear());
          n.setDate(m.getDate() + 7);
          // m.setMonth(n.getMonth());
          // m.setFullYear(n.getFullYear());
          // m.setDate(n.getDate() - 15);
          // console.log("fin", m);
          // console.log("els case", n.getMonth());
          this.fromDate = m.toISOString().substr(0, 10);
          this.toDate = n.toISOString().substr(0, 10);
          this.getData();
        }
      } else {
        m.setMonth(n.getMonth());
        m.setFullYear(n.getFullYear());
        m.setDate(n.getDate() - 7);
        this.fromDate = m.toISOString().substr(0, 10);

        this.getData();
      }
    },
    formatDate(item) {
      var defDate = new Date(item);
      defDate = defDate.toString().slice(0, 15);
      var today = new Date();
      today = today.toString().slice(0, 15);

      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.toString().slice(0, 15);

      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(0, 4) + day + " " + dt.slice(4, 7) + " " + year;

      if (today == defDate) {
        return "Today";
      } else if (yesterday == defDate) {
        return "Yesterday";
      } else {
        return strTime;
      }
    },
    formatDateMonth(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}-${year}`;
    },
    formatDatePicker(date) {
      var dt = new Date(date);
      var day = String(dt.getDate()).padStart(2, "0"); // Ensure two digits
      var month = String(dt.getMonth() + 1).padStart(2, "0"); // Ensure two digits
      var year = dt.getFullYear();
      var strTime = day + "-" + month + "-" + year;
      return strTime;
    },
  },
};
</script>
  <style >
.selected-date {
  /* cursor: pointer; */
  min-height: 30x !important;
  max-width: 100% !important;
}
</style>
  